<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :src="userData?.picture"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData?.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center avatar-with-info">
      <b-avatar
        size="40"
        :src="userData?.picture"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData?.name" icon="UserIcon" size="22" />
      </b-avatar>
      <div class="dropdown-username-role">
        <span v-if="checkIfUserFullData()" class="dropdown__username-header"
          >{{ userData?.given_name }} {{ userData?.family_name }}</span
        >
        <span
          v-if="!checkIfUserFullData()"
          class="dropdown__username-header dropdown__username-header--transform"
          >{{ userData?.email }}</span
        >
        <span class="dropdown__username-email">{{ userData?.email }}</span>
      </div>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :href="whitelabel.accountUrl"
      target="_blank"
    >
      <GjIcon name="Settings" class="mr-50" size="20" />
      <span>
        {{ $t('dropdownMenu.accountSettings') }}
      </span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <template
      v-if="organizationsLength > 1 && $route.fullPath !== '/organizations'"
    >
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="switchOrganization"
      >
        <GjIcon name="RefreshAlt" class="mr-50" size="20" />
        <span>{{ $t('dropdownMenu.switchOrganization') }}</span>
      </b-dropdown-item>

      <b-dropdown-divider />
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import { whitelabel } from '@/util'
import { avatarText } from '@core/utils/filter'
import { logoutUser } from '@nodus/authentication'
import { GjIcon, getUserData } from '@nodus/utilities-front'
import {
  BAvatar,
  BDropdownDivider,
  BDropdownItem,
  BNavItemDropdown
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    GjIcon
  },
  data() {
    return {
      userData: getUserData().profile,
      avatarText,
      whitelabel
    }
  },
  computed: {
    ...mapGetters({
      organizationsLength: 'accountManagement/organizationsLength'
    })
  },
  beforeMount() {
    this.$store.dispatch('accountManagement/getOrganizations')
  },
  methods: {
    logout() {
      logoutUser()
    },
    checkIfUserFullData() {
      return !!(this.userData.given_name && this.userData.family_name)
    },
    switchOrganization() {
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE', false)
      this.$router.push('/organizations')
    }
  }
}
</script>
