var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-container d-flex content align-items-center" },
    [
      _c(
        "ul",
        {
          staticClass: "nav navbar-nav",
          class: { "d-none": _vm.hideMenuToggler },
        },
        [
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "b-link",
                {
                  staticClass: "nav-link",
                  on: { click: _vm.toggleVerticalMenuActive },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MenuIcon", size: "21" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex",
        },
        [
          _c(
            "div",
            {
              staticClass: "active-product__logo",
              class: { integrate__logo: _vm.whitelabel.title === "integr8" },
            },
            [
              _c("img", {
                attrs: {
                  src: require(`@/assets/images/logo/${_vm.whitelabel.logoUrl}`),
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "b-navbar-nav",
        { staticClass: "nav align-items-center ml-auto" },
        [_c("user-dropdown")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }