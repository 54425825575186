<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
      external: item.external
    }"
    @click="toggleMenu"
  >
    <component
      :is="item.external ? 'a' : 'b-link'"
      v-bind="
        item.external ? { href: item.route, target: '_blank' } : linkProps
      "
      class="d-flex align-items-center"
    >
      <img :src="getMenuItemIcon(item.icon)" class="product__icon" />
      <span
        v-if="item.description"
        class="menu-title text-truncate"
        :class="{ 'external-link': !!item.external }"
      >
        <span>{{ item.title }}</span>
        <span>{{ item.description }}</span>
      </span>
      <span v-else class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </component>
  </li>
</template>

<script>
import store from '@/store'
import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { BBadge, BLink } from 'bootstrap-vue'
import { computed } from 'vue'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isVerticalMenuActive = computed(
      () => store.state.verticalMenu.isVerticalMenuActive
    )
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    const getMenuItemIcon = (icon) =>
      icon
        ? require(`@assets/images/products/${icon}.svg`)
        : '@assets/images/products/bisko-min.svg'

    const toggleMenu = () => {
      store.commit(
        'verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE',
        !isVerticalMenuActive.value
      )
    }

    return {
      isActive,
      linkProps,
      updateIsActive,
      getMenuItemIcon,
      toggleMenu,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-content .nav-item img {
  margin-right: 8px;
}
</style>
