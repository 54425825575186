var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "error-page" },
      [
        _c("b-img", {
          attrs: { fluid: "", src: _vm.imgUrl, alt: "Error page" },
        }),
        _c("div", { staticClass: "error-page__text" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t(`errorPages.${_vm.code}.title`)))]),
          _vm.$t(`errorPages.${_vm.code}.message`) !==
          `errorPages.${_vm.code}.message`
            ? _c("span", [
                _vm._v(_vm._s(_vm.$t(`errorPages.${_vm.code}.message`))),
              ])
            : _vm._e(),
          _c("div", { staticClass: "error-page__actions" }, [
            _vm.code != 401 && _vm.previousPath
              ? _c(
                  "button",
                  {
                    staticClass: "refresh-button",
                    on: { click: _vm.refreshPage },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("errorPages.refreshPage")) + " ")]
                )
              : _vm._e(),
            _c(
              "button",
              {
                staticClass: "back-button",
                on: { click: _vm.returnToHomePage },
              },
              [_vm._v(" " + _vm._s(_vm.$t("errorPages.backToHomePage")) + " ")]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }