export default [
  {
    path: '/bisko/:organizationId/lookalike',
    name: 'lookalike',
  },
  {
    path: '/bisko/:organizationId/lookalike/:pathMatch(.*)*',
    name: 'lookalike-general',
    meta: {
      navActiveLink: 'lookalike',
    },
  },
]
