import Vue, { computed } from 'vue'
import VueRouter from 'vue-router'

// Store

// Auth Store
import { authStore } from '@nodus/authentication'

// Block route function
import { isBlockedForLite } from '@nodus/utilities-front'

// Routes
import { whitelabel } from '@/util'
import { canNavigate, isUserLoggedIn } from '@nodus/authentication'

// Our routes
import accountManagement from './routes/account-management'
import analytics from './routes/analytics'
import audience from './routes/audience'
import biskql from './routes/biskql'
import data from './routes/data'
import lookalike from './routes/lookalike'
import misc from './routes/misc'
import organization from './routes/organization'
import visualizer from './routes/visualizer'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'organizations' } },
    ...accountManagement,
    ...organization,
    ...biskql,
    ...(whitelabel.title !== 'integr8' ? visualizer : []),
    ...data,
    ...audience,
    ...analytics,
    ...lookalike,
    ...misc,
    {
      path: '*',
      redirect: '/error/404'
    }
  ]
})

const organizationPlan = computed(() => authStore.getters['organization/plan'])
const organizationId = computed(() => authStore.getters['organization/id'])

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next('/error/403')
  }

  // Set currentOrganizationId
  if (!organizationId.value && to.params.organizationId) {
    await authStore.dispatch(
      'organization/getDetails',
      to.params.organizationId
    )
  }

  if (organizationPlan.value === 'Lite') {
    const blockRoute = isBlockedForLite(to.path, organizationPlan.value)

    if (blockRoute) return next('/error/403')
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
