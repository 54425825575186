import { whitelabel } from '@/util'

export default [
  {
    path: '/bisko/:organizationId/analytics',
    name: 'analytics',
  },
  {
    path: '/bisko/:organizationId/analytics/:pathMatch(.*)*',
    name: 'analytics-general',
    meta: {
      navActiveLink: whitelabel.title !== 'integr8' ? 'visualizer' : 'analytics',
    },
  },
]
