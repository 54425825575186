<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul
      class="nav navbar-nav"
      :class="{'d-none': hideMenuToggler}"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div
        class="active-product__logo"
        :class="{'integrate__logo' : whitelabel.title === 'integr8'}"
      >
        <img :src="require(`@/assets/images/logo/${whitelabel.logoUrl}`)">
      </div>
      <!-- Bookmarks Container -->
      <!-- <div class="breadcrumb__custom">
        <AppBreadcrumb />
      </div> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
// import Locale from './components/Locale.vue'
// import DarkToggler from './components/DarkToggler.vue'
import { computed } from 'vue'
// import AppBreadcrumb from '../AppBreadcrumb.vue'
import router from '@/router'
import { whitelabel } from '@/util'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    // Locale,
    // DarkToggler,
    // AppBreadcrumb,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const hideMenuToggler = computed(() => ['organizations', 'error-page'].some(val => val === router.app.$route.name))

    return {
      hideMenuToggler,
      whitelabel,
    }
  },
}
</script>

<style lang="scss" scoped>
.active-product__logo {
  font-size: 24px;
  margin: 0 14px;
}

.integrate__logo {
  position: absolute;
}
</style>
