var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canViewVerticalNavMenuLink(_vm.item)
    ? _c(
        "li",
        {
          staticClass: "nav-item",
          class: {
            active: _vm.isActive,
            disabled: _vm.item.disabled,
            external: _vm.item.external,
          },
          on: { click: _vm.toggleMenu },
        },
        [
          _c(
            _vm.item.external ? "a" : "b-link",
            _vm._b(
              { tag: "component", staticClass: "d-flex align-items-center" },
              "component",
              _vm.item.external
                ? { href: _vm.item.route, target: "_blank" }
                : _vm.linkProps,
              false
            ),
            [
              _c("img", {
                staticClass: "product__icon",
                attrs: { src: _vm.getMenuItemIcon(_vm.item.icon) },
              }),
              _vm.item.description
                ? _c(
                    "span",
                    {
                      staticClass: "menu-title text-truncate",
                      class: { "external-link": !!_vm.item.external },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.item.title))]),
                      _c("span", [_vm._v(_vm._s(_vm.item.description))]),
                    ]
                  )
                : _c("span", { staticClass: "menu-title text-truncate" }, [
                    _vm._v(_vm._s(_vm.t(_vm.item.title))),
                  ]),
              _vm.item.tag
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mr-1 ml-auto",
                      attrs: {
                        pill: "",
                        variant: _vm.item.tagVariant || "primary",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }