import axios from '@axios'

export const accountManagement = {
  organizations: {
    activateOrganizationUsers: (organizationId = '', invitationCode = '') =>
      axios.post(`/api/v1/${organizationId}/users/activate`, {
        invitationCode
      }),
    activatePropertyUsers: (organizationId = '', invitationCode = '') =>
      axios.post(`/api/v1/${organizationId}/property/users/activate`, {
        invitationCode
      })
  }
}
