var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canViewHorizontalNavMenuHeaderGroup(_vm.item)
    ? _c(
        "li",
        {
          staticClass: "dropdown nav-item",
          class: {
            "sidebar-group-active active open": _vm.isActive,
            show: _vm.isOpen,
          },
          on: {
            mouseenter: () => _vm.updateGroupOpen(true),
            mouseleave: () => _vm.updateGroupOpen(false),
          },
        },
        [
          _c(
            "b-link",
            {
              staticClass: "nav-link dropdown-toggle d-flex align-items-center",
            },
            [
              _c("feather-icon", {
                attrs: { size: "14", icon: _vm.item.icon },
              }),
              _c("span", [_vm._v(_vm._s(_vm.t(_vm.item.header)))]),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "dropdown-menu" },
            _vm._l(_vm.item.children, function (child) {
              return _c(_vm.resolveHorizontalNavMenuItemComponent(child), {
                key: child.title,
                tag: "component",
                attrs: { item: child },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }