import { whitelabelConfig } from '@nodus/utilities-front'

export const destructJSONObject = (object, key) => {
  const {
    data: {
      data: { [key]: data }
    }
  } = object

  return data
}

export const whitelabel = whitelabelConfig[process.env.VUE_APP_BRAND]
