var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout-horizontal",
    [
      _c("router-view"),
      _vm.showCustomizer
        ? _c("app-customizer", {
            attrs: { slot: "customizer" },
            slot: "customizer",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }