export default [
  {
    path: '/bisko/:organizationId/visualizer',
    name: 'visualizer',
  },
  {
    path: '/bisko/:organizationId/visualizer/:pathMatch(.*)*',
    name: 'visualizer-general',
    meta: {
      navActiveLink: 'visualizer',
    },
  },
]
