export default [
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import('@/views/account-management/Organizations.vue'),
    meta: {
      // layout: 'full',
      pageTitle: 'Organizations',
      showAppContent: true,
      breadcrumb: [
        {
          text: 'Account Management',
        },
        {
          text: 'Organizations',
          active: true,
        },
      ],
    },
  },
]
