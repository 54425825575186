export default [
  {
    path: '/bisko/:organizationId/audience',
    name: 'audience',
  },
  {
    path: '/bisko/:organizationId/audience/:pathMatch(.*)*',
    name: 'audience-general',
    meta: {
      navActiveLink: 'audience',
    },
  },
]
