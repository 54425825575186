export default [
  {
    path: '/bisko/:organizationId/data',
    name: 'data',
  },
  {
    path: '/bisko/:organizationId/data/:pathMatch(.*)*',
    name: 'data-general',
    meta: {
      navActiveLink: 'data',
    },
  },
]
