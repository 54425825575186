export default [
  {
    path: '/bisko/:organizationId/organization',
    name: 'organization',
  },
  {
    path: '/bisko/:organizationId/organization/:pathMatch(.*)*',
    name: 'organization-general',
    meta: {
      navActiveLink: 'organization',
    },
  },
]
