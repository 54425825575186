import { $themeBreakpoints } from '@themeConfig'

export default {
  watch: {
    $route() {
      if (this.$store.state.app.windowWidth < $themeBreakpoints.xl) {
        this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_ACTIVE', false)
      }
    },
  },
}
