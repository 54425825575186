var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav-item",
    {
      on: {
        click: function ($event) {
          _vm.skin = _vm.isDark ? "light" : "dark"
        },
      },
    },
    [
      _c("feather-icon", {
        attrs: { size: "21", icon: `${_vm.isDark ? "Sun" : "Moon"}Icon` },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }