var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.items, function (item) {
      return _c(_vm.resolveNavItemComponent(item), {
        key: item.header || item.title,
        tag: "component",
        attrs: { item: item },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }