var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-user",
      attrs: {
        right: "",
        "toggle-class": "d-flex align-items-center dropdown-user-link",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c(
                "b-avatar",
                {
                  staticClass: "badge-minimal",
                  attrs: {
                    size: "40",
                    src: _vm.userData?.picture,
                    variant: "light-primary",
                    badge: "",
                    "badge-variant": "success",
                  },
                },
                [
                  !_vm.userData?.name
                    ? _c("feather-icon", {
                        attrs: { icon: "UserIcon", size: "22" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "d-flex align-items-center avatar-with-info" },
        },
        [
          _c(
            "b-avatar",
            {
              staticClass: "badge-minimal",
              attrs: {
                size: "40",
                src: _vm.userData?.picture,
                variant: "light-primary",
                badge: "",
                "badge-variant": "success",
              },
            },
            [
              !_vm.userData?.name
                ? _c("feather-icon", {
                    attrs: { icon: "UserIcon", size: "22" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "dropdown-username-role" }, [
            _vm.checkIfUserFullData()
              ? _c("span", { staticClass: "dropdown__username-header" }, [
                  _vm._v(
                    _vm._s(_vm.userData?.given_name) +
                      " " +
                      _vm._s(_vm.userData?.family_name)
                  ),
                ])
              : _vm._e(),
            !_vm.checkIfUserFullData()
              ? _c(
                  "span",
                  {
                    staticClass:
                      "dropdown__username-header dropdown__username-header--transform",
                  },
                  [_vm._v(_vm._s(_vm.userData?.email))]
                )
              : _vm._e(),
            _c("span", { staticClass: "dropdown__username-email" }, [
              _vm._v(_vm._s(_vm.userData?.email)),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            "link-class": "d-flex align-items-center",
            href: _vm.whitelabel.accountUrl,
            target: "_blank",
          },
        },
        [
          _c("GjIcon", {
            staticClass: "mr-50",
            attrs: { name: "Settings", size: "20" },
          }),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("dropdownMenu.accountSettings")) + " "),
          ]),
        ],
        1
      ),
      _c("b-dropdown-divider"),
      _vm.organizationsLength > 1 && _vm.$route.fullPath !== "/organizations"
        ? [
            _c(
              "b-dropdown-item",
              {
                attrs: { "link-class": "d-flex align-items-center" },
                on: { click: _vm.switchOrganization },
              },
              [
                _c("GjIcon", {
                  staticClass: "mr-50",
                  attrs: { name: "RefreshAlt", size: "20" },
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("dropdownMenu.switchOrganization"))),
                ]),
              ],
              1
            ),
            _c("b-dropdown-divider"),
          ]
        : _vm._e(),
      _c(
        "b-dropdown-item",
        {
          attrs: { "link-class": "d-flex align-items-center" },
          on: { click: _vm.logout },
        },
        [
          _c("feather-icon", {
            staticClass: "mr-50",
            attrs: { size: "16", icon: "LogOutIcon" },
          }),
          _c("span", [_vm._v("Logout")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }