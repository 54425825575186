import axiosIns from '@/libs/axios'
import { destructJSONObject } from '@/util'

export default {
  namespaced: true,
  state: {
    organizations: [],
    organizationsLength: 0
  },
  getters: {
    allOrganizations: (state) => state.organizations,
    organizationsLength: (state) => state.organizationsLength
  },
  actions: {
    async getOrganizations({ commit }) {
      const response = await axiosIns.get('/api/v1/organizations')

      commit('SET_ORGANIZATIONS', destructJSONObject(response, 'organizations'))
      return destructJSONObject(response, 'organizations')
    }
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizationsLength = organizations.length
      state.organizations = organizations.reduce((acc, curr) => {
        acc.push({
          id: curr.id,
          title: curr.name,
          code: curr.code,
          plan: curr.plan
        })

        return acc
      }, [])
    }
  }
}
