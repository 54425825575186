var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.allOrganizations.length > 1
    ? _c("div", [
        _c(
          "section",
          { staticClass: "select-organization" },
          [
            _c(
              "b-row",
              { class: _vm.isLoading ? "loading-state show-spinner" : null },
              [
                _vm.isLoading
                  ? _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _vm.isLoading
                          ? _c("b-spinner", { attrs: { label: "Loading..." } })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isLoading
                  ? _c("b-col", { attrs: { md: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "select-organization__container" },
                        [
                          _c("b-img", {
                            attrs: {
                              fluid: "",
                              src: _vm.organizationLogo,
                              alt: "Organization logo",
                            },
                          }),
                          _c("h6", [
                            _vm._v(_vm._s(_vm.$t("organization.choose"))),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("organization.desc"))),
                          ]),
                          _c(
                            "div",
                            { staticClass: "select-organization__list" },
                            _vm._l(
                              _vm.allOrganizations,
                              function (organization) {
                                return _c(
                                  "span",
                                  {
                                    key: organization.id,
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClick(organization)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(organization.title))]
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }