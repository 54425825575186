import { whitelabel } from '@/util'

export default [
  {
    title: 'Bisko',
    description: 'Data Management Platform',
    icon: 'bisko-min',
    children: [
      {
        title: 'Organization',
        route: 'organization',
        icon: 'organization',
      },
      ...(whitelabel.title === 'integr8'
        ? [
          {
            title: 'Analytics',
            route: 'analytics',
            icon: 'analytics',
          },
        ]
        : [
          {
            title: 'Visualizer',
            route: 'visualizer',
            icon: 'visualizer',
          },
        ]),
      {
        title: 'Data',
        route: 'data',
        icon: 'data',
      },
      {
        title: 'Audience',
        route: 'audience',
        icon: 'audience',
      },
      {
        title: 'Lookalike',
        route: 'lookalike',
        icon: 'lookalike',
      },
      {
        title: 'Biskql',
        route: 'biskql',
        icon: 'biskql-min',
      },
    ],
  },
  {
    title: 'Porta',
    description: 'Identity Management Platform',
    icon: 'porta-min',
    external: true,
    route: 'https://accounts-admin.gjirafa.dev/',
  },
  {
    title: 'Tonos',
    description: 'Service Subscription Platform',
    icon: 'tonos-min',
    external: true,
    route: 'https://admin.tonos.gjirafa.tech/',
  },
  {
    title: 'Tru AI',
    description: 'AI Modeling Platform',
    icon: 'truai-min',
    external: true,
    route: 'https://aihub.gjirafa.tech/',
  },
  {
    title: 'Codex',
    description: 'Content Management System',
    icon: 'codex-min',
    external: true,
    route: 'https://v2.codex.gjirafa.tech/',
  },
  {
    title: 'VP',
    description: 'Video Player',
    icon: 'vp-min',
    external: true,
    route: 'https://client.vp.gjirafa.tech/',
  },
  {
    title: 'Captain',
    description: 'Cloud Services',
    icon: 'captain-min',
    external: true,
    route: 'https://console.captain.gjirafa.tech/',
  },
]
