import { Login, OidcCallback } from '@nodus/authentication'
import { i18n } from '@nodus/utilities-front'

export default [
  {
    path: '/callback', // Needs to match redirectUri in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      layout: 'full',
      isPublic: true,
      resource: 'Auth'
    }
  },
  {
    path: '/login',
    name: 'auth-login',
    component: Login,
    meta: {
      isPublic: true,
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/error/:code',
    name: 'error-page',
    component: () => import('@/views/pages/ErrorPage.vue'),
    meta: {
      // layout: 'full',
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Error page',
      showAppContent: true,
      refreshPath: null,
      breadcrumb: [
        {
          text: 'Error page',
          active: true
        }
      ]
    },
    beforeEnter(to, from, next) {
      if (from.name) to.meta.refreshPath = from.fullPath
      to.meta.pageTitle = i18n.getLocaleMessage(
        `errorPages.${to.params.code}.title`
      )
      to.meta.breadcrumb[0].text = i18n.getLocaleMessage(
        `errorPages.${to.params.code}.title`
      )
      next()
    }
  }
]
