<template>
  <div>
    <!-- Error page-->
    <div class="error-page">
      <b-img fluid :src="imgUrl" alt="Error page" />
      <div class="error-page__text">
        <h2>{{ $t(`errorPages.${code}.title`) }}</h2>
        <span
          v-if="
            $t(`errorPages.${code}.message`) !== `errorPages.${code}.message`
          "
          >{{ $t(`errorPages.${code}.message`) }}</span
        >
        <div class="error-page__actions">
          <button
            v-if="code != 401 && previousPath"
            class="refresh-button"
            @click="refreshPage"
          >
            {{ $t('errorPages.refreshPage') }}
          </button>

          <button class="back-button" @click="returnToHomePage">
            {{ $t('errorPages.backToHomePage') }}
          </button>
        </div>
      </div>
    </div>
    <!-- / Error page-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store'
import { BImg } from 'bootstrap-vue'
import { onBeforeMount, onUnmounted } from 'vue'

export default {
  components: {
    BImg
  },
  setup() {
    onBeforeMount(() => {
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    })
    onUnmounted(() => {
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    })
  },
  data() {
    const { code } = this.$route.params
    return {
      code,
      downImg: require(`@/assets/images/icons/error-pages/${code}.svg`),
      previousPath: this.$route.meta.refreshPath
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    }
  },
  methods: {
    returnToHomePage() {
      this.$router.push('/')
    },
    refreshPage() {
      this.$router.push(this.previousPath)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/pages/error';
</style>
