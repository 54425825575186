<script>
/* eslint-disable global-require */
import { accountManagement } from '@/api'
import router from '@/router'
import store from '@/store'
import { authStore, OIDC_STORE_MODULE } from '@nodus/authentication'
import { BCol, BImg, BRow, BSpinner } from 'bootstrap-vue'
import { computed, onBeforeMount, onUnmounted } from 'vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BSpinner
  },
  setup() {
    const organizationLogo = require('@/assets/images/icons/organization-icon.svg')

    const invPageType = localStorage.getItem('redirectInvitationPageType')
    const redirectPage = localStorage.getItem('redirectToInvitationPage')
    const isPropertyUsersPage = invPageType === 'PropertyUsers'

    const orgKeyFromURL = redirectPage?.split('/')[1]
    const invCodeFromURL = isPropertyUsersPage
      ? redirectPage?.split('/')[4]
      : redirectPage?.split('/')[3]
    let displayOrganizationView = false

    // This is only needed temporarily because of the changes on local storage user data structure
    const clearOldLoginUserData = () => {
      const userData = window.localStorage.getItem(
        `oidc.user:${process.env.VUE_APP_AUTHORITY}:bisko`
      )

      const userEmail = JSON.parse(userData)?.profile?.email

      if (!userEmail) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userData')
        authStore.dispatch(`${OIDC_STORE_MODULE}/authenticateOidc`)
      }
    }

    onBeforeMount(async () => {
      clearOldLoginUserData()
      if (orgKeyFromURL && invCodeFromURL) {
        localStorage.setItem('Organization', orgKeyFromURL)
        if (isPropertyUsersPage) {
          await accountManagement.organizations.activatePropertyUsers(
            orgKeyFromURL,
            invCodeFromURL
          )
        } else {
          await accountManagement.organizations.activateOrganizationUsers(
            orgKeyFromURL,
            invCodeFromURL
          )
          localStorage.setItem('redirectToInvitationPage', null)
          localStorage.setItem('redirectInvitationPageType', null)
        }
      }
      const data = await store.dispatch('accountManagement/getOrganizations')

      if (data?.length === 0) {
        router.push({ name: 'error-page', params: { code: 404 } })
      } else if (data?.length === 1) {
        if (data[0]?.plan === 'Lite')
          router.push(`/bisko/${data[0]?.code}/organization/properties`)
        else router.push(`/bisko/${data[0]?.code}/analytics/real-time`)
      } else {
        displayOrganizationView = true
        store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      }
    })

    const allOrganizations = computed(
      () => store.getters['accountManagement/allOrganizations']
    )
    const isLoading = computed(() => store.state.app.isLoading)

    onUnmounted(() => {
      if (allOrganizations.value.length !== 1)
        store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    })

    const handleClick = async (organization) => {
      authStore.commit('organization/SET_ID', organization.code)
      authStore.commit('organization/SET_PLAN', organization.plan)
      if (organization?.plan === 'Lite')
        router.push(`/bisko/${organization?.code}/organization/properties`)
      else router.push(`/bisko/${organization?.code}/analytics/real-time`)
    }

    return {
      organizationLogo,
      allOrganizations,
      isLoading,
      handleClick,
      displayOrganizationView
    }
  }
}
</script>

<template>
  <div v-if="allOrganizations.length > 1">
    <section class="select-organization">
      <b-row :class="isLoading ? 'loading-state show-spinner' : null">
        <b-col v-if="isLoading" md="12">
          <b-spinner v-if="isLoading" label="Loading..." />
        </b-col>
        <b-col v-if="!isLoading" md="12">
          <div class="select-organization__container">
            <b-img fluid :src="organizationLogo" alt="Organization logo" />
            <h6>{{ $t('organization.choose') }}</h6>
            <span>{{ $t('organization.desc') }}</span>
            <div class="select-organization__list">
              <span
                v-for="organization in allOrganizations"
                :key="organization.id"
                @click="handleClick(organization)"
                >{{ organization.title }}</span
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/views/account-management/organizations.scss';
</style>
