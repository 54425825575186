var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canViewVerticalNavMenuGroup(_vm.item)
    ? _c(
        "li",
        {
          staticClass: "nav-item has-sub",
          class: {
            open: _vm.isOpen,
            disabled: _vm.item.disabled,
            "sidebar-group-active": _vm.isActive,
          },
        },
        [
          _c(
            "b-link",
            {
              staticClass: "d-flex align-items-center",
              on: { click: () => _vm.updateGroupOpen(!_vm.isOpen) },
            },
            [
              _c("img", { attrs: { src: _vm.getMenuItemIcon(_vm.item.icon) } }),
              _c("span", { staticClass: "menu-title text-truncate" }, [
                _c("span", [_vm._v(_vm._s(_vm.item.title))]),
                _c("span", [_vm._v(_vm._s(_vm.item.description))]),
              ]),
              _vm.item.tag
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mr-1 ml-auto",
                      attrs: {
                        pill: "",
                        variant: _vm.item.tagVariant || "primary",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-collapse",
            {
              staticClass: "menu-content",
              attrs: { tag: "ul" },
              model: {
                value: _vm.isOpen,
                callback: function ($$v) {
                  _vm.isOpen = $$v
                },
                expression: "isOpen",
              },
            },
            _vm._l(
              _vm.item.children.filter((c) => !c.hidden),
              function (child) {
                return _c(_vm.resolveNavItemComponent(child), {
                  key: child.header || child.title,
                  ref: "groupChild",
                  refInFor: true,
                  tag: "component",
                  attrs: { item: child },
                })
              }
            ),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }