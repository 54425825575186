export default [
  {
    path: '/bisko/:organizationId/biskql',
    name: 'biskql',
  },
  {
    path: '/bisko/:organizationId/biskql/:pathMatch(.*)*',
    name: 'biskql-general',
    meta: {
      navActiveLink: 'biskql',
    },
  },
]
