import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'
import './set-public-path'

import { BootstrapVue, ModalPlugin, ToastPlugin } from 'bootstrap-vue'

import { i18n } from '@nodus/utilities-front'
import App from './App.vue'
import router from './router'
import store from './store'

// 3rd party plugins
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'

// Boostrap Vue
Vue.use(BootstrapVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    el: '#nodus-admin',
    render: (h) => h(App),
    router,
    store,
    i18n
  }
})

export const { bootstrap } = vueLifecycles
export const { mount } = vueLifecycles
export const { unmount } = vueLifecycles
